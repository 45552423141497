<template>
  <div class="driverRecruitment">
    <div class="banner">
      <div class="text">
        <p>迪尔出行</p>
        <p>司机招募中</p>
        <p>扫码立即加入</p>
      </div>
    </div>
    <div class="content">
      <div class="top">
        <p>迪尔车主为签约司机的服务端，司机能通过此应用接单、抢单、实现相关资质认证以及实现订单金</p>
        <p>额的结算等。我们为车主提供最简单最智能最便捷和最专业的服务，</p>
        <p>抢先成为迪尔出行认证司机，开始疯狂挣钱吧!</p>
      </div>
      <div class="advantages-wrapper">
        <div class="title-wrapper">
          <UnderlineTitle>我们的优势</UnderlineTitle>
        </div>
        <ul>
          <li v-for="item in advantagesList" :key="item.id">
            <img :src="item.imgSrc" alt="">
            <span>{{ item.text_1 }}</span>
            <span>{{ item.text_2 }}</span>
          </li>
        </ul>
      </div>
      <div class="bottom">
        <p>一部分司机进行自营，退伍军人优先录用，提供五险一金，提供车辆，</p>
        <p>以工作制的方式进入迪尔，成为迪尔标星司机</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'driverRecruitment',
    data(){
      return {
        advantagesList: [
          {
            id: 1,
            imgSrc: require('../assets/driver_recruitment/advantage_1.png'),
            text_1: '搭建网约车', 
            text_2: '司机优质体验', 
          },
          {
            id: 2,
            imgSrc: require('../assets/driver_recruitment/advantage_2.png'),
            text_1: '全心全意为', 
            text_2: '司机着想谋福利', 
          },
          {
            id: 3,
            imgSrc: require('../assets/driver_recruitment/advantage_3.png'),
            text_1: '深化迭代', 
            text_2: '自身顺路单系统', 
          },
          {
            id: 4,
            imgSrc: require('../assets/driver_recruitment/advantage_4.png'),
            text_1: '车辆调度系统', 
            text_2: '减少司机空驶', 
          },
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
.driverRecruitment {
  .banner {
    background-image: url('../assets/driver_recruitment/banner.png');
    background-position: center;
    background-size: 100% 100%;
    height: 36.98vw;
    min-width: 960px;
    min-height: 400px;
    position: relative;
    > .text {
      position: absolute;
      left: 66.67%;
      top: 47.61%;
      > p {
        color: #000;
        text-align: left;
        font-size: 2.18vw;
        // font-size: 42px;
        font-weight: 200;
      }
      > p:first-child {
        font-size: 3.33vw;
        // font-size: 64px;
        color: #010101;
        margin-bottom: 40px;
        font-weight: normal;
      }
    }
  }
  .content {
    text-align: center;
    background-color: #f7f7f7;
    > .top,
    > .bottom {
      padding: 90px 0;
      > p {
        line-height: 2;
        font-weight: 300;
      }
      > p:nth-child(3){
        font-weight: 800;
      }
    }
    > .advantages-wrapper {
      // border: 1px solid red;
      width: 67.71vw;
      margin: 0 auto;
      padding-bottom: 140px;
      border-radius: 24px;
      box-shadow: 0 0 5px 1px rgba(0,0,0,0.2);
      > .title-wrapper {
        background-image: url('../assets/driver_recruitment/preponderance.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 90% auto;
        padding: 110px 0 90px 0;
      }
      > ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        // padding: 0 128px 0 138px;
        padding: 0 6.67vw 0 7.19vw;
        > li {
          text-align: center;
          > img {
            margin-bottom: 35px;
            width: 6.9;
          }
          > span {
            display: block;
            font-size: 24px;
            // font-size: 1.25vw;
            line-height: 1.5;
            font-weight: 200;
          }
        }
      }
    }
    // > .bottom {
    //   padding: 90px 0;
    //   > p {
    //     line-height: 2;
    //   }
    // }
  }
}
</style>